<template>
  <v-container fluid>
    <v-widget title="CRUD" no-padding>
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca" />
            </v-col>

            <v-col cols="4" sm="7" class="text-right">
              <v-btn depressed fab small :loading="carregando" @click="listar">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-btn v-if="!$vuetify.breakpoint.smAndDown" color="primary" depressed @click="novo" data-cy="novo" class="ml-2">Novo Item</v-btn>

              <v-btn v-else depressed fab small color="primary" @click="novo" class="ml-2">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table tile :options.sync="options" :server-items-length="total" :headers="headers" :items="itens" :loading="carregando">
          <template v-slot:item="{ item }">
            <tr>
              <td style="width: 20%">{{ item.codigo }}</td>
              <td style="width: 40%">{{ item.descricao }}</td>
              <td style="width: 20%" class="text-right">{{ item.valor | formatarMoeda }}</td>
              <td style="width: 20%" class="text-right">{{ item.estoque | formatarValor }}</td>
              <td class="text-right">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id" @click="editar(item._id)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'Código', value: 'codigo', width: '20%' },
        { text: 'Descrição', value: 'descricao', width: '40%' },
        { text: 'Valor', value: 'valor', align: 'right', width: '20%', sortable: false },
        { text: 'Estoque', value: 'estoque', align: 'right', width: '20%', sortable: false },
        { text: '', value: 'name', align: 'center', sortable: false, width: '32px' },
      ],
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  computed: {
    ...mapState('crud', {
      itens: 'itens',
      carregando: 'carregando',
      total: 'total',
    }),
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  methods: {
    novo() {
      this.$store.commit('crud/ZERAR');
      this.$router.push({ name: 'crudNovo' });
    },

    async editar(id) {
      this.$router.push({ name: 'crudEditar' });
      await this.$store.dispatch('crud/encontrarPeloId', id);
    },

    listar() {
      this.$store.dispatch('crud/listar', this.options);
    },

    limparBusca() {
      this.listar();
    },

    filtrar(event) {
      const newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('crud/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    excluir(id) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('crud/excluir', id);
        }
      });
    },
  },
};
</script>
